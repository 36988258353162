<template>
	<div class="popup popup02">
    <v-card tile>
      <v-card-title class="popup-title">{{ header }}</v-card-title>
      <div class="mt-4">
        <fieldset class="pp">
          연구소명
          <input v-model="param.keyword" type="text" maxlength="" style="width:40%" class="blue_input" placeholder="검색어를 입력해 주세요" @keyup.enter="search">
          <button type="button" class="pp_s" @click="search">검색</button>
          <v-simple-table
            class="mt-4 popup-table"
            fixed-header
            height="500px"
            style="border-top: 2px solid #2c3976 !important"
          >
            <template v-slot:default>
            <colgroup>
              <col width="10%">
              <col width="40%">
              <col width="50%">
            </colgroup>
            <thead>
            <tr>
              <th class="th_dark_blue"></th>
              <th class="th_dark_blue" @click="setSort('labCode')">연구소코드 <v-icon v-if="checkSort('labCode')" small>{{ checkSort('labCode') }}</v-icon></th>
              <th class="th_dark_blue" @click="setSort('labName')">연구소명 <v-icon v-if="checkSort('labName')" small>{{ checkSort('labName') }}</v-icon></th>
            </tr>
            </thead>
            <thead v-if="loading">
            <tr>
              <th colspan="3" class="td_loading">
                <v-progress-linear
                  indeterminate
                  color="#0f2585"
                ></v-progress-linear>
              </th>
            </tr>
            </thead>
            <tbody v-if="items.length > 0">
            <tr v-for="(item,index) in items" :key="index" :class="{'selected': selectedIndex === index, 'even': index % 2 > 0}">
              <td class="td_blue cen bo font-weight-medium" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">
                <input type="checkbox" v-model="selectedItems" :value="item.labCode" @change="onCheckChanged(index, $event)">
              </td>
              <td class="td_blue cen bo font-weight-medium" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.labCode }}</td>
              <td class="td_blue cen bo font-weight-medium" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.labName }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="no-data" colspan="3">데이터를 찾을 수 없습니다.</td>
            </tr>
            </tbody>
            </template>
          </v-simple-table>

<!--          <tech-pagination :page.sync="pageable.page" :total-elements="pageable.totalElements"/>-->
          <div class="butt">
            <button id="confirm01" type="button" class="pop-btn" @click="select">확인</button>
            <button id="close02" type="button" class="pop-btn" @click="close">취소</button>

          </div>

        </fieldset>
      </div>
    </v-card>
	</div>
</template>

<script>
// import TechPagination from '@/components/Pagination'
export default {
	name: 'LabDialog',
  // components: {TechPagination},
	props: {
    header: String,
		labCodes: String,
    labNames: String
	},
	data() {
		return {
      loading: false,
			param: {
        start: 1,
        end: 10,
				keyword: null,
        sort: 'labCode',
			},
      pageable: {
        page: 1,
        totalElements: 0
      },
			items: [],
			selectedItem: {},
			selectedItems: [],
      selectedNames: [],
			selectedIndex: null,
      editLabCodes: []
		}
	},
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.activateMultipleDraggableDialogs()
    if (this.labCodes) {
      this.selectedItems = [].concat(this.labCodes.split(','))
      this.selectedNames = [].concat(this.labNames.split(','))
    }
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      // if (!this.param.keyword) {
      //   alert('검색어를 입력해 주세요.')
      //   return
      // }
      // this.param.start = (this.pageable.page - 1) * 10;
      // this.param.end = this.pageable.page * 10

      this.loading = true
			this.$http.get('/api/popup/lab', {params: this.param})
			.then(({ data }) => {
				this.items = data
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
				this.selectedIndex = null
				this.selectedItem = null

        this.loading = false
			})
		},
		itemSelect(index, item) {
			this.selectedIndex = index
			this.selectedItem = item
		},
		itemSelectClose(index, item) {
			this.itemSelect(index, item)
			this.select()
		},
		select() {
			if (!this.selectedItem) {
				alert('항목을 선택해 주세요.')
				return
			}

			this.$emit('close', {
        labCodes: this.selectedItems.join(','),
        labNames: this.selectedNames.join(',')
      })
		},
		close() {
			this.$emit('close')
		},
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
    onCheckChanged(index, evt) {
      const item = this.items[index]
      if (evt.target.checked) {
        this.selectedNames.push(item.labName)
      } else {
        this.selectedNames.splice(this.selectedNames.indexOf(item.labName), 1)
      }
    }
	}
}
</script>

<style scoped>
/*.popup-table table thead {*/
/*  border-top: 2px solid #2c3976 !important;*/
/*}*/
.popup-table table thead tr th.th_dark_blue {
  BORDER-BOTTOM: #c3c3c3 1px solid;
  BACKGROUND-COLOR: #f0f2f5;
  LINE-HEIGHT: 37px;
  text-align: center !important;
  COLOR: #16168a;
  FONT-SIZE: 15px;
  border-right: 1px solid rgb(214, 214, 214);
  border-left: 1px solid rgb(214, 214, 214);
  cursor: pointer;
}

</style>

<template>
	<div class="popup popup02">
    <v-card tile>
      <v-card-title class="popup-title">{{ header }}</v-card-title>
      <div class="mt-4">
        <fieldset class="pp">
          검색기준
          <select v-model="param.searchType" class="sky" style="width:19%">
            <option value="name">1.성명</option>
            <option value="regNo">2.주민등록번호</option>
            <option value="studentNo">3.학번</option>
          </select>

          <input v-model="param.keyword" type="text" maxlength="" style="width:25%" class="blue_input" placeholder="검색어를 입력해 주세요" @keyup.enter="search">
          <button type="button" class="pp_s" @click="search">검색</button>
          <table class="popup-table">
            <colgroup>
              <col width="5%">
              <col width="8%">
              <col width="15%">
              <col width="20%">
              <col width="7%">
              <col width="10%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead>
            <tr>
              <th class="td_dark_blue">NO.</th>
              <td class="td_dark_blue">성명</td>
              <td class="td_dark_blue">소속</td>
              <td class="td_dark_blue">주민번호</td>
              <td class="td_dark_blue">상태</td>
              <td class="td_dark_blue">신분구분</td>
              <td class="td_dark_blue">연락처</td>
              <td class="td_dark_blue">이메일</td>
            </tr>
            </thead>
            <thead v-if="loading">
            <tr>
              <th colspan="8" class="td_loading">
                <v-progress-linear
                  indeterminate
                  color="#0f2585"
                ></v-progress-linear>
              </th>
            </tr>
            </thead>
            <tbody v-if="items.length > 0">
            <tr v-for="(item,index) in items" :key="index" :class="{'selected': selectedIndex === index, 'even': index % 2 > 0}">
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.rnum }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.korName }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.sosokName }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.formattedJuminNo }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.nowStatus }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.managerTypeName }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.tel }}</td>
              <td class="td_dark_gray cen bo" @click="itemSelect(index, item)" @dblclick="itemSelectClose(index, item)">{{ item.email }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="no-data" colspan="8">데이터를 찾을 수 없습니다.</td>
            </tr>
            </tbody>

          </table>

          <tech-pagination :page.sync="pageable.page" :total-elements="pageable.totalElements"/>

          <div class="butt">
            <button id="confirm01" type="button" class="pop-btn" @click="select">확인</button>
            <button id="close02" type="button" class="pop-btn" @click="close">취소</button>

          </div>

        </fieldset>
      </div>
    </v-card>
	</div>
</template>

<script>
import TechPagination from '@/components/Pagination'
export default {
	name: 'ManagerDialog',
  components: {TechPagination},
	props: {
		header: String
	},
	data() {
		return {
      loading: false,
			param: {
        start: 1,
        end: 10,
				managerType: '1',
				searchType: 'name',
				keyword: null,
			},
      pageable: {
        page: 1,
        totalElements: 0
      },
			items: [],
			selectedItem: {},
			selectedIndex: null,
		}
	},
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.activateMultipleDraggableDialogs()
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      if (!this.param.keyword) {
        alert('검색어를 입력해 주세요.')
        return
      }
      this.param.start = (this.pageable.page - 1) * 10;
      this.param.end = this.pageable.page * 10

      this.loading = true
			this.$http.get('/api/popup/manager', {params: this.param})
			.then(({ data }) => {
				this.items = data
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
				this.selectedIndex = null
				this.selectedItem = null

        this.loading = false
			})
		},
		itemSelect(index, item) {
			this.selectedIndex = index
			this.selectedItem = item
		},
		itemSelectClose(index, item) {
			this.itemSelect(index, item)
			this.select()
		},
		select() {
			if (!this.selectedItem) {
				alert('항목을 선택해 주세요.')
				return
			}

			this.$emit('close', this.selectedItem)
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<template>
  <v-container fluid>
    <p class="text-h5 text-left">사용자 관리</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label style="display: inline-block; width: 100px; text-align: right;">권한명</label>
      <input v-model="param.authName" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <button type="button" class="pp_s" @click="search">검색</button>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="20%">
        <col width="20%">
        <col width="30%">
        <col width="20%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue">권한코드</th>
        <th class="td_dark_blue">권한명</th>
        <th class="td_dark_blue">설명</th>
        <th class="td_dark_blue">사용여부</th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="5" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ index + 1 }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authCode }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authDesc }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">
            {{ item.useYn === 'Y' ? '사용' : '' }}
          </td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="5">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="10%">
        <col width="20%">
        <col width="20%">
        <col width="10%">
        <col v-if="showLab" width="20%">
        <col width="10%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue">사용자코드</th>
        <th class="td_dark_blue">사용자명</th>
        <th class="td_dark_blue">소속</th>
        <th class="td_dark_blue">신분구분</th>
        <th v-if="showLab" class="td_dark_blue">소속연구소</th>
        <th class="td_dark_blue">
          <v-btn
            small
            elevation="0"
            color="primary"
            @click="addItem"
            :disabled="selectedIndex < 0"
          >
            추가
          </v-btn>
        </th>
      </tr>
      </thead>
      <thead v-if="userLoading">
      <tr>
        <th colspan="5" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="users.length > 0">
      <template v-for="(item,index) in users">
        <tr :key="index" :class="selectedUserIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelectUser(item, index)">{{ index + 1 }}</td>
          <td class="td_blue cen bo" @click="itemSelectUser(item, index)">{{ item.userCode }}</td>
          <td class="td_blue cen bo" @click="itemSelectUser(item, index)">{{ item.userName }}</td>
          <td class="td_blue cen bo" @click="itemSelectUser(item, index)">{{ item.userPart }}</td>
          <td class="td_blue cen bo" @click="itemSelectUser(item, index)">{{ item.userClass }}</td>
          <td v-if="showLab" class="td_blue cen bo" @click="itemSelectUser(item, index)">
            <p
              v-for="labName in item.labNameList"
              :key="labName"
              class="mb-0"
              style="line-height: 24px"
            >
              {{ labName }}
            </p>
          </td>
          <td class="td_blue cen bo">
            <v-btn small elevation="0" color="#bfc2c5" @click="removeItem(item, index)">삭제</v-btn>
          </td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" :colspan="showLab ? 7 : 6">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="20%">
        <col width="30%">
        <col width="20%">
        <col width="30%">
      </colgroup>
      <tbody>
      <tr>
        <th class="td_dark_blue"><label for="txtUserCode">사용자코드</label></th>
        <td class="td_blue cen bo">
          <input
            v-model="selectedUser.userCode"
            type="text"
            style="width:90%"
            class="blue_input"
            id="txtUserCode"
            disabled
          />
          <v-btn
            small
            icon
            color="primary"
            width="32px"
            height="32px"
            @click="userDialog = true"
            :disabled="selectedUserIndex < 0"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </td>
        <th class="td_dark_blue"><label for="txtUserName">사용자명</label></th>
        <td class="td_blue cen bo">
          <input
            v-model="selectedUser.userName"
            type="text"
            style="width:96%"
            class="blue_input"
            id="txtUserName"
          />
        </td>
      </tr>
      <tr>
        <th class="td_dark_blue"><label for="txtUserPart">소속</label></th>
        <td class="td_blue cen bo">
          <input
            v-model="selectedUser.userPart"
            type="text"
            style="width:96%"
            class="blue_input"
            id="txtUserPart"
          />
        </td>
        <th class="td_dark_blue"><label for="txtUserClass">신분구분</label></th>
        <td class="td_blue cen bo">
          <input
            v-model="selectedUser.userClass"
            class="blue_input"
            style="width:96%"
            type="text"
            id="txtUserClass"
          />
        </td>
      </tr>
      <tr v-if="showLab">
        <th class="td_dark_blue"><label for="txtUserClass">소속연구소</label></th>
        <td class="td_blue cen bo">
          <input
              v-model="selectedUser.labNames"
              type="text"
              style="width:90%"
              class="blue_input"
              id="txtUserCode"
              disabled
          />
          <v-btn
              small
              icon
              color="primary"
              width="32px"
              height="32px"
              @click="labDialog = true"
              :disabled="selectedUserIndex < 0"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </td>
      </tr>
      </tbody>

    </table>

    <div class="text-right mt-2">
      <v-btn small class="mr-2" elevation="0" color="primary" @click="saveItem">저장</v-btn>
    </div>

    <v-dialog v-model="userDialog" max-width="800px" persistent @keydown.esc="userDialog = false">
      <manager-dialog v-if="userDialog" header="사용자 검색" @close="closeUserDialog"></manager-dialog>
    </v-dialog>
    <v-dialog v-model="labDialog" max-width="600px" persistent @keydown.esc="labDialog = false">
      <lab-dialog v-if="labDialog" header="연구소 검색" :lab-codes="selectedUser.labCodes" :lab-names="selectedUser.labNames" @close="closeLabDialog"></lab-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import ManagerDialog from '@/views/sys/dialog/ManagerDialog'
import LabDialog from '@/views/sys/dialog/LabDialog'
export default {
  name: 'UserManage',
  components: {LabDialog, ManagerDialog},
  data() {
    return {
      param: {
        authName: null
      },
      userDialog: false,
      labDialog: false,
      loading: false,
      userLoading: false,
      items: [],
      users: [],
      selectedItem: {},
      selectedIndex: -1,
      selectedUser: {},
      selectedUserIndex: -1
    }
  },
  computed: {
    showLab() {
      return this.selectedItem && this.selectedItem.authCode === 'ROLE_RESEARCHER'
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$http.get('/api/sys/auth', {params: this.param})
      .then(({ data }) => {
        this.selectedItem = {}
        this.selectedUser = {}
        this.selectedIndex = -1
        this.items = data
        this.loading = false
      })
    },
    searchUsers(authCode) {
      this.userLoading = true
      this.$http.get('/api/sys/user', {params: {authCode}})
      .then(({ data }) => {
        this.selectedUser = {}
        this.selectedUserIndex = -1
        this.users = data
        this.userLoading = false
      })
    },
    itemSelect(item, index) {
      this.selectedItem = item
      this.selectedIndex = index
      this.searchUsers(item.authCode)
    },
    itemSelectUser(item, index) {
      this.selectedUser = item
      this.selectedUserIndex = index
    },
    addItem() {
      const newItem = {authCode: this.selectedItem.authCode, isNew: true}
      this.users.push(newItem)
      this.selectedUser = this.users[this.users.length - 1]
      this.selectedUserIndex = this.users.length - 1
    },
    saveItem() {
      delete this.selectedItem
      this.$http.post('/api/sys/user', {
        authCode: this.selectedUser.authCode,
        userCode: this.selectedUser.userCode,
        userName: this.selectedUser.userName,
        userPart: this.selectedUser.userPart,
        userClass: this.selectedUser.userClass,
        labCodes: this.selectedUser.labCodes,
        socialNumber: this.selectedUser.socialNumber
      })
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.search()
        }
      })
    },
    removeItem(item, index) {
      if (item.isNew) {
        this.users.splice(index, 1)
      } else {
        if (confirm('선택한 항목을 삭제하겠습니까?')) {
          this.$http.post('/api/sys/user/remove', {
            authCode: item.authCode,
            userCode: item.userCode,
            labCodes: item.labCodes
          })
          .then(({data}) => {
            if (data === 'success') {
              alert('삭제되었습니다.')
              this.searchUsers(item.authCode)
            }
          })
        }
      }
    },
    closeUserDialog(selected) {
      this.userDialog = false
      if (selected) {
        this.selectedUser.userCode = selected.studentNo
        this.selectedUser.userName = selected.korName
        this.selectedUser.userPart = selected.sosokName
        this.selectedUser.userClass = selected.managerTypeName
        this.selectedUser.socialNumber = selected.formattedJuminNo
      }
    },
    closeLabDialog(selected) {
      this.labDialog = false
      if (selected) {
        this.selectedUser.labCodes = selected.labCodes
        this.selectedUser.labNames = selected.labNames
        this.selectedUser.labNameList = selected.labNames.split(',')
      }
    }
  }
}
</script>
